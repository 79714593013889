import React, { useContext, useState, Fragment, useEffect } from 'react';
import toUpper from 'lodash/toUpper';
import get from 'lodash/get';
import Text from 'ni-ui/text';
import { IconButton } from 'ni-ui/svg-icon';
import Input, { CheckBox } from 'ni-ui/input';
import ToolTip from 'ni-ui/tooltip';
import Info from 'ni-ui/icon/lib/Info';
import Colors from 'ni-ui/colors';
import Close from 'ni-ui/icon/lib/Close';
import AmountInput from 'ni-ui/amount-input';
import { PropTypes } from 'prop-types';
import CardIcons from '../../card-icons';
import { AppContext } from '../../../app-context';
import styles from './styles.scss';
import { formatExpiry } from './utils';
import { amountToMinor, getMinorUnitBy } from '../../../utils';


const SplitPaymentCard = ({
  card, cardsSelected, setCardsSelected, balance, removeCard
}) => {
  const {
    orderDetails
  } = useContext(AppContext);

  const splitPaymentCardsLimit = get(orderDetails, 'splitPaymentCardsLimit');
  const selectedCards = cardsSelected.some(selected => selected.cardToken === card.cardToken);
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(selectedCards);
  const [cardDetails, setCardDetails] = useState({
    cvv: card.cvv || '',
    amount: 0
  });
  const showCvv = get(card, 'recaptureCsc', '') !== 'false';

  const orderAmount = orderDetails.order.amount;

  useEffect(() => {
    if (isCheckBoxSelected) {
      const updatedCard = { ...card, ...cardDetails };
      setCardsSelected(cardsSelected.map(selectedCard =>
        (selectedCard.cardToken === updatedCard.cardToken ? updatedCard : selectedCard)));
    }
  }, [cardDetails, isCheckBoxSelected]);
  const cardScheme = card.scheme;
  const Icon = CardIcons[toUpper(cardScheme)];

  const selectCard = () => {
    if (isCheckBoxSelected) {
      const filterSelectedCards = cardsSelected.filter(selectedCard =>
        selectedCard.cardToken !== card.cardToken);
      setCardsSelected(filterSelectedCards);
    } else {
      const newCard = { ...card, ...cardDetails };
      setCardsSelected([...cardsSelected, newCard]);
    }
    setIsCheckBoxSelected(!isCheckBoxSelected);
  };


  const selectAndUpdateCardDetails = (value) => {
    setCardDetails(prevDetails => ({ ...prevDetails, ...value }));

    if (value.amount === 0) {
      setIsCheckBoxSelected(false);

      setCardsSelected(cardsSelected.filter(selectedCard =>
        selectedCard.cardToken !== card.cardToken));
    } else {
      const isCardSelected = cardsSelected.some(selectedCard =>
        selectedCard.cardToken === card.cardToken);
      const updatedCard = { ...card, ...cardDetails, ...value };
      if (isCardSelected) {
        setCardsSelected(cardsSelected.map(selectedCard =>
          (selectedCard.cardToken === card.cardToken
            ? updatedCard
            : selectedCard)));
      } else {
        setCardsSelected([...cardsSelected, updatedCard]);
        setIsCheckBoxSelected(true);
      }
    }
  };

  return (
    <Fragment>
      <div
        className={
          (cardsSelected.length >= splitPaymentCardsLimit || !balance) && !isCheckBoxSelected
            ? styles.splitPaymentCardDisabled
            : styles.splitPaymentCard
        }
      >
        <div className={styles.splitPaymentCardCheckbox}>
          <CheckBox
            selected={isCheckBoxSelected}
            color={Colors.GREY_DARK}
            onClick={() => selectCard()}
          />
        </div>
        <div className={styles.savedCardContainer}>
          <div className={styles.cardDetails}>
            {Icon ? <Icon size={32} data-testid={cardScheme} /> : null}
            <div className={styles.cardDataContainer}>
              <Text
                textKey="SAVED_CARD"
                values={{
                  ...card,
                  expiry: formatExpiry(card.expiry)
                }}
              />
            </div>
            {showCvv && (
              <div className={`${styles.cvvContainer} ${styles.securityCodeFont}`}>
                <Input
                  type="password"
                  labelKey="CVV"
                  onChange={val => selectAndUpdateCardDetails({ cvv: val })}
                  value={cardDetails.cvv}
                />
                <div className={styles.toolTipContainer}>
                  <ToolTip textKey="CVV_TOOLTIP">
                    <Info size={12} />
                  </ToolTip>
                </div>
              </div>
            )}
            <div>
              <AmountInput
                error={false}
                defaultValue={0}
                isCharactersAllowed={false}
                large={false}
                labelKey="AMOUNT"
                minorUnit={getMinorUnitBy(orderAmount.currencyCode)}
                onChange={(val) => {
                  const extractedAmountValue = val.formattedValue.replace(/\u00A0/g, ' ').replace(/,/g, '').split(' ')[1];
                  selectAndUpdateCardDetails({
                    amount: {
                      // eslint-disable-next-line max-len
                      value: amountToMinor(Number(extractedAmountValue), getMinorUnitBy(orderAmount.currencyCode)),
                      currencyCode: orderAmount.currencyCode
                    }
                  });
                }}
                currency={orderAmount.currencyCode}
                maximumFractionDigits={getMinorUnitBy(orderAmount.currencyCode)}
              />
            </div>
            {card.isAddedCard &&
              <div style={{ marginLeft: 'auto' }}>
                <IconButton
                  data-testid="remove-button"
                  icon={<Close size={20} color={Colors.GREY_DARK} />}
                  onClick={removeCard}
                />
              </div>}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

SplitPaymentCard.defaultProps = {
  balance: 0
};

SplitPaymentCard.propTypes = {
  card: PropTypes.shape({}).isRequired,
  balance: PropTypes.number,
  cardsSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCardsSelected: PropTypes.func.isRequired,
  removeCard: PropTypes.func.isRequired
};

export default SplitPaymentCard;
